var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
    },
    [
      _vm.formData
        ? _c(
            "el-form",
            {
              staticClass: "form",
              attrs: {
                model: _vm.formData,
                "label-position": "left",
                "label-width": "200px",
              },
            },
            [
              _vm.showField(
                _vm.COMPANY_SETTINGS_PROPERTIES.autoSendToMpa.isRestricted
              )
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "flex-row",
                      attrs: {
                        label:
                          _vm.COMPANY_SETTINGS_PROPERTIES.autoSendToMpa
                            .displayText,
                      },
                    },
                    [
                      _c("el-switch", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.formData.autoSendToMpa,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "autoSendToMpa", $$v)
                          },
                          expression: "formData.autoSendToMpa",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showField(
                _vm.COMPANY_SETTINGS_PROPERTIES.trialEbdn.isRestricted
              )
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "flex-row",
                      attrs: {
                        label:
                          _vm.COMPANY_SETTINGS_PROPERTIES.trialEbdn.displayText,
                      },
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.formData.trialEbdn,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "trialEbdn", $$v)
                          },
                          expression: "formData.trialEbdn",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showField(
                _vm.COMPANY_SETTINGS_PROPERTIES.creditCheck.isRestricted
              )
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "flex-row",
                      attrs: {
                        label:
                          _vm.COMPANY_SETTINGS_PROPERTIES.creditCheck
                            .displayText,
                      },
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.formData.creditCheck,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "creditCheck", $$v)
                          },
                          expression: "formData.creditCheck",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showField(
                _vm.COMPANY_SETTINGS_PROPERTIES.cargoOfficerTransferJob
                  .isRestricted
              )
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "flex-row",
                      attrs: {
                        label:
                          _vm.COMPANY_SETTINGS_PROPERTIES
                            .cargoOfficerTransferJob.displayText,
                      },
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.formData.cargoOfficerTransferJob,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "cargoOfficerTransferJob",
                              $$v
                            )
                          },
                          expression: "formData.cargoOfficerTransferJob",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showField(
                _vm.COMPANY_SETTINGS_PROPERTIES.cargoOfficerCancelJob
                  .isRestricted
              )
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "flex-row",
                      attrs: {
                        label:
                          _vm.COMPANY_SETTINGS_PROPERTIES.cargoOfficerCancelJob
                            .displayText,
                      },
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.formData.cargoOfficerCancelJob,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "cargoOfficerCancelJob", $$v)
                          },
                          expression: "formData.cargoOfficerCancelJob",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showField(
                _vm.COMPANY_SETTINGS_PROPERTIES.sgTradexParticipantId
                  .isRestricted
              )
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "flex-row",
                      attrs: {
                        label:
                          _vm.COMPANY_SETTINGS_PROPERTIES.sgTradexParticipantId
                            .displayText,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: { size: "medium" },
                        model: {
                          value: _vm.formData.sgTradexParticipantId,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "sgTradexParticipantId", $$v)
                          },
                          expression: "formData.sgTradexParticipantId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showField(
                _vm.COMPANY_SETTINGS_PROPERTIES.pushB2BViaSgTradex.isRestricted
              )
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "flex-row",
                      attrs: {
                        label:
                          _vm.COMPANY_SETTINGS_PROPERTIES.pushB2BViaSgTradex
                            .displayText,
                      },
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.formData.pushB2BViaSgTradex,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "pushB2BViaSgTradex", $$v)
                          },
                          expression: "formData.pushB2BViaSgTradex",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showField(
                _vm.COMPANY_SETTINGS_PROPERTIES.sgTradexPushAfter.isRestricted
              )
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "flex-row",
                      attrs: {
                        label:
                          _vm.COMPANY_SETTINGS_PROPERTIES.sgTradexPushAfter
                            .displayText,
                      },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "Select date and time",
                        },
                        model: {
                          value: _vm.formData.sgTradexPushAfter,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "sgTradexPushAfter", $$v)
                          },
                          expression: "formData.sgTradexPushAfter",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showField(
                _vm.COMPANY_SETTINGS_PROPERTIES.pushToMpaViaSgTradex
                  .isRestricted
              )
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "flex-row",
                      attrs: {
                        label:
                          _vm.COMPANY_SETTINGS_PROPERTIES.pushToMpaViaSgTradex
                            .displayText,
                      },
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.formData.pushToMpaViaSgTradex,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "pushToMpaViaSgTradex", $$v)
                          },
                          expression: "formData.pushToMpaViaSgTradex",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showField(
                _vm.COMPANY_SETTINGS_PROPERTIES.pushB2BViaSgTradexToSelf
                  .isRestricted
              )
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "flex-row",
                      attrs: {
                        label:
                          _vm.COMPANY_SETTINGS_PROPERTIES
                            .pushB2BViaSgTradexToSelf.displayText,
                      },
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.formData.pushB2BViaSgTradexToSelf,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "pushB2BViaSgTradexToSelf",
                              $$v
                            )
                          },
                          expression: "formData.pushB2BViaSgTradexToSelf",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showField(
                _vm.COMPANY_SETTINGS_PROPERTIES.ebdnDefaultRecipients
                  .isRestricted
              )
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "flex-row",
                      attrs: {
                        label:
                          _vm.COMPANY_SETTINGS_PROPERTIES.ebdnDefaultRecipients
                            .displayText,
                      },
                    },
                    [
                      _c("el-select", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          "allow-create": "",
                          filterable: "",
                          multiple: "",
                          "default-first-option": "",
                          placeholder: "Enter Emails",
                        },
                        on: { change: _vm.onEmailChange },
                        model: {
                          value: _vm.formData.ebdnDefaultRecipients,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "ebdnDefaultRecipients", $$v)
                          },
                          expression: "formData.ebdnDefaultRecipients",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showField(
                _vm.COMPANY_SETTINGS_PROPERTIES.decimalPoints.isRestricted
              )
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "flex-row",
                      attrs: {
                        label:
                          _vm.COMPANY_SETTINGS_PROPERTIES.decimalPoints
                            .displayText,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: { size: "medium", type: "number" },
                        model: {
                          value: _vm.formData.decimalPoints,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "decimalPoints", $$v)
                          },
                          expression: "formData.decimalPoints",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showField(
                _vm.COMPANY_SETTINGS_PROPERTIES.roundingDecimal.isRestricted
              )
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "flex-row",
                      attrs: {
                        label:
                          _vm.COMPANY_SETTINGS_PROPERTIES.roundingDecimal
                            .displayText,
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "350px" },
                          attrs: {
                            "default-first-option": "",
                            placeholder: "Please Select",
                          },
                          model: {
                            value: _vm.formData.roundingDecimal,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "roundingDecimal", $$v)
                            },
                            expression: "formData.roundingDecimal",
                          },
                        },
                        _vm._l(_vm.roundingDecimalOptions, function (item) {
                          return _c("el-option", {
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showField(
                _vm.COMPANY_SETTINGS_PROPERTIES.setPricingTier.isRestricted
              )
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "flex-row",
                      attrs: {
                        label:
                          _vm.COMPANY_SETTINGS_PROPERTIES.setPricingTier
                            .displayText,
                      },
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.formData.pricingTierCheck,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "pricingTierCheck", $$v)
                          },
                          expression: "formData.pricingTierCheck",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showField(
                _vm.COMPANY_SETTINGS_PROPERTIES.fullDateFormatBdn.isRestricted
              )
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "flex-row",
                      attrs: {
                        label:
                          _vm.COMPANY_SETTINGS_PROPERTIES.fullDateFormatBdn
                            .displayText,
                      },
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.formData.fullDateFormatBdn,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "fullDateFormatBdn", $$v)
                          },
                          expression: "formData.fullDateFormatBdn",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showField(
                _vm.COMPANY_SETTINGS_PROPERTIES.victory.isRestricted
              )
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "flex-row",
                      attrs: {
                        label:
                          _vm.COMPANY_SETTINGS_PROPERTIES.victory.displayText,
                      },
                    },
                    [
                      _c("el-switch", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.formData.isVictory,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "isVictory", $$v)
                          },
                          expression: "formData.isVictory",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showField(
                _vm.COMPANY_SETTINGS_PROPERTIES.exxonMobil.isRestricted
              )
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "flex-row",
                      attrs: {
                        label:
                          _vm.COMPANY_SETTINGS_PROPERTIES.exxonMobil
                            .displayText,
                      },
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.formData.isExxonMobil,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "isExxonMobil", $$v)
                          },
                          expression: "formData.isExxonMobil",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showField(
                _vm.COMPANY_SETTINGS_PROPERTIES.allowRefuseSignNop.isRestricted
              )
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "flex-row",
                      attrs: {
                        label:
                          _vm.COMPANY_SETTINGS_PROPERTIES.allowRefuseSignNop
                            .displayText,
                      },
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.formData.allowRefuseSignNop,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "allowRefuseSignNop", $$v)
                          },
                          expression: "formData.allowRefuseSignNop",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showField(
                _vm.COMPANY_SETTINGS_PROPERTIES.allowModifyShipSealNumber
                  .isRestricted
              )
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "flex-row",
                      attrs: {
                        label:
                          _vm.COMPANY_SETTINGS_PROPERTIES
                            .allowModifyShipSealNumber.displayText,
                      },
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.formData.allowModifyShipSealNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "allowModifyShipSealNumber",
                              $$v
                            )
                          },
                          expression: "formData.allowModifyShipSealNumber",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showField(
                _vm.COMPANY_SETTINGS_PROPERTIES.bdnInfoLineOne.isRestricted
              ) && _vm.isPhysicalSupplier
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "flex-row",
                      attrs: {
                        label:
                          _vm.COMPANY_SETTINGS_PROPERTIES.bdnInfoLineOne
                            .displayText,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          size: "medium",
                          type: "textarea",
                          maxlength: "120",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.formData.bdnInfoLineOne,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "bdnInfoLineOne", $$v)
                          },
                          expression: "formData.bdnInfoLineOne",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showField(
                _vm.COMPANY_SETTINGS_PROPERTIES.bdnInfoLineTwo.isRestricted
              ) && _vm.isPhysicalSupplier
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "flex-row",
                      attrs: {
                        label:
                          _vm.COMPANY_SETTINGS_PROPERTIES.bdnInfoLineTwo
                            .displayText,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          size: "medium",
                          type: "textarea",
                          maxlength: "120",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.formData.bdnInfoLineTwo,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "bdnInfoLineTwo", $$v)
                          },
                          expression: "formData.bdnInfoLineTwo",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showField(
                _vm.COMPANY_SETTINGS_PROPERTIES.bdnInfoLineThree.isRestricted
              ) && _vm.isPhysicalSupplier
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "flex-row",
                      attrs: {
                        label:
                          _vm.COMPANY_SETTINGS_PROPERTIES.bdnInfoLineThree
                            .displayText,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          size: "medium",
                          type: "textarea",
                          maxlength: "120",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.formData.bdnInfoLineThree,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "bdnInfoLineThree", $$v)
                          },
                          expression: "formData.bdnInfoLineThree",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  staticClass: "flex-end",
                  staticStyle: { "margin-top": "10px" },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "primary-button",
                      on: { click: _vm.updateSettings },
                    },
                    [_vm._v("Update")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "second-button",
                      on: { click: _vm.getSettings },
                    },
                    [_vm._v("Reset")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }